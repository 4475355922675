<template>
  <div class="app">
    <AppNavbar />
    <router-view></router-view>
    
  </div>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
  }
}
</script>

<style>
/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
}

.app {
  background: white;
}
</style>
