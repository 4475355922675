<template>
  <div class="about-us">
    <img src="@/assets/aboutus.jpeg" alt="Team Working" class="header-image" />
    <section class="mission">
      <h2>Our Mission</h2>
      <p>At Visor, our mission is to revolutionize the way insurance is purchased. We leverage AI to provide a seamless, accurate, and transparent insurance quoting process. Our goal is to empower renters with the information they need to make informed decisions, ensuring they get the best coverage at the best price.</p>
    </section>

    <section class="who-we-are">
      <h2>Who We Are</h2>
      <p>Visor is a team of dedicated professionals passionate about technology. We are committed to creating innovative solutions that brings clarity to those seeking insurance.</p>
    </section>

    <section class="what-we-do">
      <h2>What We Do</h2>
      <p>We understand that finding the right renters insurance can be overwhelming. That’s why we created Visor. Our platform uses AI vision to assess your property’s value accurately in seconds. By analyzing images and data, our AI provides precise property valuations, ensuring you get the most accurate and competitive insurance quotes available.</p>
    </section>

    <section class="our-technology">
      <h2>Our Technology</h2>
      <ul>
        <li><strong>AI-Powered Property Assessment</strong>: Our AI analyzes a video of your rental property, assessing your property value instantly. This comprehensive analysis ensures a precise valuation of your belongings.</li>
        <li><strong>Accurate Quotes</strong>: Based on the AI assessment, Visor aggregates quotes from top insurance providers. You receive customized insurance quotes that reflect the true value of your property, eliminating guesswork and ensuring you get the best possible coverage.</li>
        <li><strong>Seamless Experience</strong>: Our user-friendly platform guides you through the process effortlessly. Simply upload photos, receive your property assessment, make any changes you want, and get personalized quotes in minutes.</li>
      </ul>
    </section>

    <section class="why-choose-visor">
      <h2>Why Choose Visor?</h2>
      <ul>
        <li><strong>Precision</strong>: Our AI technology helps you understand the value of your belongings, giving you confidence in your insurance coverage.</li>
        <li><strong>Convenience</strong>: With Visor, seeing your insurance options is quick and simple. No researching or guesswork required.</li>
        <li><strong>Transparency</strong>: We aggregate quotes from reputable insurance providers, giving you a clear view of your options and helping you make informed decisions.</li>
        
      </ul>
    </section>

    <section class="join-us">
      <h2>Join Us on Our Journey</h2>
      <p>At Visor, we are committed to continually improving and innovating. We invite you to join us on this journey as we redefine the insurance landscape. Whether you’re a renter looking for better insurance options or a partner interested in our technology, we’d love to hear from you.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
.about-us {
  padding: 0rem 10vw;
}
.header-image {
  display: block;
  margin: 0 auto 0;
  max-width: 80%;
}
.about-us section {
  margin-bottom: 20px;
}

.about-us h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.about-us p,
.about-us ul {
  font-size: 1.1em;
  line-height: 1.6;
}

.about-us ul {
  padding-left: 20px;
}

.about-us li {
  margin-bottom: 10px;
}
</style>
