<template>
  <div class="contact-us">
    <div class="contact-info">
      <h1>Contact Us</h1>
      <p>If you have any questions, feel free to reach out to us!</p>
      <ul>
        <li><strong>Email:</strong> nicholas@keep-ai.com</li>
      </ul>
    </div>
    <div class="contact-image">
      <img src="@/assets/contactus.jpeg" alt="Contact Us Image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
}
</script>

<style scoped>
.contact-us {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 5vw 5vw 5vw;
}

.contact-info {
  max-width: 100%;
}

.contact-info h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.contact-info ul {
  list-style: none;
  padding: 0;
}

.contact-info li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.contact-info a {
  color: #0073b1; /* LinkedIn blue color */
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-image {
  flex: 1 1 40%; /* Flex-grow, flex-shrink, and base width */
  max-width: 50vw;
  max-width: 600px;
  display: flex;
  justify-content: center;
}

.contact-image img {
  width: 100%;
  height: auto;
}
</style>
