<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>Last updated: June 18, 2024</p>

    <section>
      <p>Thank you for choosing Visor Insurance. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please <a href="/contact">contact us.</a></p>
    </section>

    <section>
      <p>When you use our mobile application, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Apps and our services.</p>
    </section>

    <section>
      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
      <p>We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
      <p>Information automatically collected: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our app.</p>
    </section>
    
    <section>
      <h2>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
      <p>In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
      <ul>
        <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
        <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
        <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
        <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
        <li>Third Party Service Providers: We share your information with third parties that perform services for us or on our behalf, including data analysis, email delivery, hosting services, customer service, and marketing assistance. Specifically, your video data is processed by Google Gemini 1.5, an AI model that may use this data to improve its machine learning capabilities. However, it is not our responsibility whether Google uses these videos to train its models, and we do not have control over this aspect of data handling.</li>
      </ul>
    </section>

    <section>
    <h2>3. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
    <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
    </section>

    <section>
    <h2>4. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
    <p>We aim to protect your personal information through a system of organizational and technical security measures. We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
  </section>

    <section>
    <h2>5. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
    <p>In Short: You have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>
    <p>Depending on where you live, you may have rights under applicable data protection laws, including the right to request access, correct, or delete your personal information.</p>
</section>

    <section>
    <h2>6. DO WE MAKE UPDATES TO THIS POLICY?</h2>
    <p>In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date, and the updated version will be effective as soon as it is accessible. We will notify you about any changes by posting the new privacy notice on our website and/or informing you via email or in-app notification.</p>
  </section>

    <section>
    <h2>7. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
    <p>If you have questions or comments about this policy, please <a href="/contact">contact us</a></p>
</section>

    <section>
    <h2>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
    <p>Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by <a href="/contact">contacting us</a>. We will respond to your request within 30 days.</p>
  </section>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
.privacy-policy {
  padding: 0rem 10vw;
}
.privacy-policy section {
  margin-bottom: 20px;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.privacy-policy p,
.privacy-policy ul {
  font-size: 1.1em;
  line-height: 1.6;
}

.privacy-policy ul {
  padding-left: 20px;
}

.privacy-policy li {
  margin-bottom: 10px;
}
</style>
