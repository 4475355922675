<template>
  <section class="content">
    <div class="content-container">
      <h2>Do it all in three steps</h2>
      <div class="row">
        <div class="col-center">
          <p>1. Take Video</p>
          <img :src="require('@/assets/takeImage.png')" alt="Apartment drawing" class="mockup">
        </div>
        <div class="col-center">
          <p>2. Check Inventory</p>
          <img :src="require('@/assets/getInventory.png')" alt="Apartment drawing" class="mockup">
        </div>
        <div class="col-center">
          <p>3. Pick the best quote</p>
          <img :src="require('@/assets/checkQuotes.png')" alt="Apartment drawing" class="mockup">
        </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentSection'
}
</script>

<style scoped>
.content {
  padding: 5vw ;
  background: #e7e7e7;
}
.content-container {
  max-width: 100%;
  margin: 0 auto;
}
.mockup {
  max-width: 20vw;
  max-width: 300px;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col-center {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
}


</style>
