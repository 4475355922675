<template>
  <footer class="footer">
    <div class="footer-content">
      <h2>Coming soon!</h2>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
.footer {
  padding: 2rem;
  max-width: 100%;
  background: #000;
  color: white;
  text-align: center;
}
</style>
