<template>
  <div class="home">
    <HeroSection />
    <ContentSection />
    <AppFooter />
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import ContentSection from '@/components/ContentSection.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    ContentSection,
    AppFooter
  }
}
</script>
