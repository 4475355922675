<template>
  <section class="hero">
    <div class="hero-content">
      <h1>Protect what matters, easily</h1>
      <p>Our platform uses AI to seamlessly capture and price all your household items through a simple video, providing you with instant insurance quotes. Effortlessly secure your assets with precise valuations, ensuring you get the best coverage and peace of mind.</p>
      <button class="hero-button">Coming Soon</button>
    </div>
    <div>
      <img :src="require('@/assets/hero2.jpeg')" alt="Apartment drawing" class="heropic">
    </div>
    
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style scoped>
.heropic{
  width: 50vw;
  min-width: 20rem;
}
.hero {
  display: flex;
  
  flex-wrap: wrap-reverse;
  
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem;
  
  background-size: cover;
  color: black;
}

.hero-content, .heropic {
  flex: 1 1 360px;
}

.hero-content {
  max-width: 40%;
  min-width: 20rem;
}
.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}
.hero-button {
  padding: 1rem 2rem;
  margin-right: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
  color: #000
}
.hero-button.primary {
  background-color: #000;
  color: #fff;
}
</style>
