<template>
  <div class="terms-of-service">
    <h1>Terms of Service</h1>
    <p>Effective Date: January 31, 2024</p>

    <section>
      <h2>Thank You for Choosing Visor Insurance</h2>
      <p>These Terms of Service ("Terms") govern your use of the Visor Insurance mobile application, and any associated services (collectively, "Services"). By accessing or using our Services, you agree to be bound by these Terms and our <a href="/privacy">Privacy Policy</a>.</p>
    </section>

    <section>
      <h2>Who We Are</h2>
      <p>Visor Insurance is dedicated to revolutionizing the way renters insurance is quoted and purchased. We use advanced AI technology to analyze video data of properties to provide accurate insurance valuations.</p>
    </section>

    <section>
      <h2>Eligibility</h2>
      <p>You must be at least 18 years old to use our Services. By agreeing to these Terms, you represent and warrant that you are legally qualified to enter into a binding contract.</p>
    </section>

    <section>
      <h2>Registration and Account Integrity</h2>
      <p>To access certain features of our Services, you may be required to register for an account. You must provide accurate and complete information. You are responsible for the security of your account and all activities that occur under your account.</p>
    </section>

    <section>
      <h2>Using Our Services</h2>
      <p>Subject to your compliance with these Terms, you are granted a limited, non-exclusive, non-transferable license to use our Services. You may not use our Services for any illegal or unauthorized purpose.</p>
      <ul>
        <li>Do not misuse our Services by interfering with their normal operation or attempting to access them using a method other than through the interfaces and instructions we provide.</li>
        <li>You may not use the Services to harm others or the Services themselves. For example, you may not use the Services to harm, threaten, or harass another person, organization, or Visor Insurance.</li>
      </ul>
    </section>

    <section>
      <h2>Content Ownership and License</h2>
      <p>You retain all your ownership rights in original aspects of your content. By submitting content to our Services, you grant Visor Insurance a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content in any and all media or distribution methods (now known or later developed).</p>
    </section>

    <section>
      <h2>Third-Party Services</h2>
      <p>Our Services may include tools provided by third parties. Visor Insurance does not monitor or have any control over these third-party services. We are not liable for the actions or inactions of third-party services.</p>
    </section>

    <section>
      <h2>Disclaimers and Limitation of Liability</h2>
      <p>The Services are provided on an "as is" and "as available" basis. Visor Insurance expressly disclaims all warranties of any kind, whether express or implied. We do not guarantee that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis.</p>
      <p>We will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.</p>
    </section>

    <section>
      <h2>Termination</h2>
      <p>We may terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
    </section>

    <section>
      <h2>Changes to the Terms</h2>
      <p>We reserve the right to modify these Terms at any time. If we make material changes to the Terms, we will notify you by updating the date at the top of these Terms and by maintaining a visible notification of such changes. By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised Terms.</p>
    </section>

    <section>
      <h2>Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of your location, without regard to its conflict of law provisions.</p>
    </section>

    <section>
      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please <a href="/contact">contact us</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService'
}
</script>

<style scoped>
.terms-of-service{
  padding: 0rem 10vw;
}

.terms-of-service h1, h2 {
  color: #333;
}
.terms-of-service p, ul, li {
  color: #666;
  font-size: 1.1em;
  line-height: 1.6;
}
.terms-of-service ul {
  padding-left: 20px;
}
.terms-of-service li {
  margin-bottom: 10px;
}
</style>
